<template>
    <div class="home">
        <v-row>
            <v-col cols="12">
                <v-btn to="/magazyn/login"> przejdź do logowania </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {store} from "@/store/store";

export default defineComponent({
    name: "StartView",
    computed: {
        store() {
            return store
        }
    },
    setup() {
        return {};
    },
});
</script>
