import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AddNewGear from "@/views/AddNewGear.vue";
import EditGear from "@/views/EditGear.vue";
import NewRent from "@/views/NewRent.vue";
import RentDetails from "@/views/RentDetails.vue";
import GearList from "@/views/GearList.vue";
import RentList from "@/views/RentList.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import {store} from "@/store/store";
import Error403 from "@/views/Error403.vue";
import {url} from "@/consts/consts";
import StartView from "@/views/StartView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "StartView",
    component: StartView,
  },
  {
    path: "/magazyn",
    name: "HomeView",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/magazyn/addNewGear",
    name: "addNewGear",
    component: AddNewGear,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/editGear/:id",
    name: "editGear",
    component: EditGear,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/newRent",
    name: "newRent",
    component: NewRent,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/rentDetails/:id",
    name: "rentDetails",
    component: RentDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/gearList",
    name: "gearList",
    component: GearList,
    meta: { requiresAuth: true },
  },
  {
    path: "/magazyn/rentList",
    name: "rentList",
    component: RentList,
    meta: { requiresAuth: true },
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
  },
  {
    path: "/error-403",
    name: "error403",
    component: Error403,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if(to.name === 'LoginPage' || to.matched.some(record => !record.meta.requiresAuth)){
    next();
  } else {
    try {
      const token = localStorage.getItem('jwtToken');
      console.log(to)
      if(to.matched.some(record => record.meta.requiresAuth) && !token){
        next({ name: 'LoginPage' });
      } else {
        const response = await fetch(
            `${url}/api/check-token`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
            }
        );
        const responseData = await response.json();
        if(response.status === 200){
          store.userData = responseData.user
          next();
        } else if(response.status === 401){
          next({ name: 'LoginPage' });
        }
      }
    } catch {
      // next({ name: 'LoginPage' });
    }
  }
});

export default router;