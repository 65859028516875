<template>
  <GoogleLogin
    :clientId="'136546612994-83v3lh48ope8setdem53b17ttqmuh33v.apps.googleusercontent.com'"
    :callback="callback"
  />
</template>
<script setup>
import { decodeCredential } from "vue3-google-login";
import { store } from '@/store/store'
import router from './../router/index'
import {url} from "@/consts/consts";


const callback = async (response) => {
    console.log("Handle the responseee", response);
    const userData = decodeCredential(response.credential);
    console.log("Handle the userData", userData);
    try {
        const res = await fetch(`${url}/api/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: response.credential })
        });
        const data = await res.json();
        console.log("Server response", data.user);
        localStorage.setItem('jwtToken', data.jwtToken);
        store.userData = data.user
        store.JWTauth = data.jwtToken

        await router.push({name: 'HomeView'});

    } catch (error) {
        window.location.reload(true);
        console.error("Login failed", error);
    }
};
</script>
